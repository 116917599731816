












import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default defineComponent({
  name: 'Question503',
  components: {STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        aiQuestionResponse: null,
      },
    };
  },
});
